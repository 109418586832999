document.addEventListener('DOMContentLoaded', () => {
	// Front page carousel
	const titleCarousel = document.querySelector('.hero .title ul');
	if (titleCarousel) {
		const items = titleCarousel.querySelectorAll('li');

		setInterval(() => {
			const currentItem = titleCarousel.querySelector('.is-active');
			const nextItem = currentItem.nextElementSibling || items[0];
			const previousItem = currentItem.previousElementSibling || items[items.length - 1];

			currentItem.className = 'is-away';
			nextItem.className = 'is-active';
			previousItem.className = '';
		}, 2000);
	}
});
